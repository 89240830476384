import _RequestClient from "./RequestClient.js";
import _Provider from "./Provider.js";
import _SearchProvider from "./SearchProvider.js";
import _Socket from "./Socket.js";
var exports = {};

/**
 * Manages communications with Companion
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Socket = exports.SearchProvider = exports.RequestClient = exports.Provider = void 0;
const _0 = _RequestClient;
exports.RequestClient = _0;
const _1 = _Provider;
exports.Provider = _1;
const _2 = _SearchProvider;
exports.SearchProvider = _2;
const _3 = _Socket;
exports.Socket = _3;
export default exports;
export const __esModule = exports.__esModule,
      Socket = exports.Socket,
      SearchProvider = exports.SearchProvider,
      RequestClient = exports.RequestClient,
      Provider = exports.Provider;