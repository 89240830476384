var exports = {};

class AuthError extends Error {
  constructor() {
    super("Authorization required");
    this.name = "AuthError";
    this.isAuthError = true;
  }

}

exports = AuthError;
export default exports;